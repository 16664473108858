import { useState, useTransition, ChangeEvent, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SORTING, PRODUCT_KEY } from 'shared/constants';
import { useAppDispatch } from 'shared/hooks';
import { getColumnFilters } from 'shared/lib';
import { TableProductInfo } from 'shared/models';
import { setCatalogSorting, setCatalogFilters } from 'shared/slices';

export const useCatalogItemsFilter = (
  field: string,
  sorting: { field: string; value: SORTING },
  filters: { field: string; values: string[] }[],
  open: boolean,
  onClose: () => void,
  items: TableProductInfo[],
  hidedItems: TableProductInfo[],
  allComponents: TableProductInfo[]
) => {
  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();

  const isSortedByCurrentField = field === sorting?.field;
  const isFilteredByCurrentField = filters?.map((f) => f.field)?.includes(field);

  const isSelectedASC = isSortedByCurrentField && sorting?.value === SORTING.ASC;
  const isSelectedDESC = isSortedByCurrentField && sorting?.value === SORTING.DESC;

  const [sortingValue, setSortingValue] = useState<SORTING | null>(isSortedByCurrentField ? sorting.value : null);
  const [searchValue, setSearchValue] = useState('');
  const [checkedFilterValues, setCheckedFilterValues] = useState<string[]>([]);

  const [isPendingTransition, startTransition] = useTransition();

  const allUniqItemsValues = useMemo(() => {
    const allItems = [...hidedItems, ...items];
    return getColumnFilters(allItems, field, filters);
  }, [allComponents, items, hidedItems]);

  const isNoFiltering = allUniqItemsValues.length === checkedFilterValues.length;
  const uniqItemsValues = allUniqItemsValues.sort().map((name, i) => ({ id: i, name }));

  const customSort = (a: string, b: string) => {
    const aNum = parseFloat(a);
    const bNum = parseFloat(b);
    if (!isNaN(aNum) && !isNaN(bNum)) {
      return aNum - bNum;
    } else if (!isNaN(aNum) && isNaN(bNum)) {
      return -1;
    } else if (isNaN(aNum) && !isNaN(bNum)) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  };

  const searchedItemsValues = uniqItemsValues
    .filter((item) => item.name)
    .filter(({ name }) => name.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    .sort((a, b) => customSort(a.name.toString(), b.name.toString()));

  const sortedSearchedItemsValues = [...searchedItemsValues].sort((a, b) => customSort(a.name, b.name));

  useEffect(() => {
    if (open) {
      if (searchValue) setSearchValue('');

      const filterItem = filters?.find((f) => f.field === field);

      if (!filterItem) {
        setCheckedFilterValues(allUniqItemsValues);
        return;
      }

      const values = filterItem.values.filter((value) => allUniqItemsValues.includes(value));
      setCheckedFilterValues(values);
    }
  }, [open]);

  const handleChangeSort = (value: SORTING) => {
    if (value === sorting?.value && isSortedByCurrentField) {
      setSortingValue(field === PRODUCT_KEY.DESC ? value : null);
    } else {
      setSortingValue(value);
    }

    startTransition(() => {
      if (value === sorting?.value && isSortedByCurrentField) {
        onSetDefaultSorting();
      } else {
        onChangeSort(field, value);
      }
    });
  };

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const onChangeCheckedFilterValues = (value: string) => {
    setCheckedFilterValues(() => {
      if (checkedFilterValues.includes(value)) {
        return checkedFilterValues.filter((v) => v !== value);
      } else {
        return [...checkedFilterValues, value];
      }
    });
  };

  const handleToggleSelectAll = () => setCheckedFilterValues(isNoFiltering ? [] : allUniqItemsValues);

  const handleOK = () => {
    const isAllItemsSelected = allUniqItemsValues.length === checkedFilterValues.length;
    const isNoSelectedItems = checkedFilterValues.length === 0;

    if (isAllItemsSelected) {
      const updatedFilters = filters.filter((f) => f.field !== field);
      onChangeFilters(updatedFilters);
    }

    if (!isNoSelectedItems && !isAllItemsSelected) {
      let updatedFilters: { field: string; values: string[] }[];
      const filter = filters?.find((f) => f.field === field);

      if (filter) {
        updatedFilters = filters.map((f) => (f.field === field ? { ...f, values: checkedFilterValues } : f));
      } else {
        updatedFilters = [...filters, { field, values: checkedFilterValues }];
      }

      onChangeFilters(updatedFilters);
    }
    onClose();
  };

  const handleResetFilter = () => {
    onResetFilter(field);
    onClose();
  };

  const onChangeSort = (field: string, sorting: SORTING) =>
    catalogId && dispatch(setCatalogSorting({ catalogId, field, sorting }));
  const onSetDefaultSorting = () =>
    catalogId && dispatch(setCatalogSorting({ catalogId, field: PRODUCT_KEY.DESC, sorting: SORTING.ASC }));
  const onChangeFilters = (filters: { field: string; values: string[] }[]) =>
    catalogId && dispatch(setCatalogFilters({ catalogId, filters }));
  const onResetFilter = (field: string) => {
    const updatedFilters = filters.filter((f) => f.field !== field);
    catalogId && dispatch(setCatalogFilters({ catalogId, filters: updatedFilters }));
  };

  return {
    checkedFilterValues,
    onChangeCheckedFilterValues,
    handleChangeSort,
    sortingValue,
    isPendingTransition,
    isSelectedASC,
    isSelectedDESC,
    searchValue,
    onChangeSearchValue,
    allUniqItemsValues,
    handleToggleSelectAll,
    sortedSearchedItemsValues,
    isFilteredByCurrentField,
    handleResetFilter,
    handleOK,
  };
};
