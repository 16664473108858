import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { AddItemButton, ItemsBox } from 'shared/ui';
import { useManageGroups } from '../hooks';
import { SubTypeItem } from 'entities/SubType/SubTypeItem';
import { GroupItem } from 'entities/Group/components/GroupItem';
import { BrandItem } from 'entities/Brand/BrandItem';
import { separateByUpperCase } from 'shared/lib';
import { RefObject, useRef, useState } from 'react';
import { CATALOG_TYPE } from 'shared/constants';
import { selectCatalogsLoading, selectCatalogTypes, selectCurrentCatalog } from '../../../shared/slices';
import { CatalogPermission } from '../../../shared/enum';

export const BoxHeader = styled(Typography)(() => ({
  padding: '4px 8px',
  fontWeight: 600,
  textTransform: 'capitalize',
}));

export const Item = styled(Button)(({ theme }) => ({
  padding: '5px !important',
  borderBottom: '1px solid grey',
  borderColor: theme.palette.info.light,
  color: 'black',
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  borderRadius: 0,
  '&:hover': { bgcolor: 'primary.light' },
}));

export const GroupsSubtypesBrands = () => {
  const { t } = useTranslation();

  const catalogTypes = useAppSelector(selectCatalogTypes);
  const isCatalogLoading = useAppSelector(selectCatalogsLoading);

  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const isViewer = currentCatalog.shared?.permission?.includes(CatalogPermission.Read);

  const {
    type,
    onChangeManageType,
    NEW_ITEM_ID,
    groupItems,
    subtypeItems,
    brandItems,
    loadingIDs,
    onChangeSubtype,
    onDeleteSubtype,
    onCreateSubtype,
    onCreateGroup,
    onDeleteGroup,
    onChangeGroup,
    onCreateBrand,
    onDeleteBrand,
    onChangeBrand,
    selectedIDs,
    onChangeSelectedIDs,
    onArrowKeyPress,
    isGroupsDisabled,
  } = useManageGroups();

  const selectedItemRef = useRef<HTMLElement>(null);

  const [activeBox, setActiveBox] = useState<'' | 'type' | 'group' | 'subtype' | 'brand'>('');

  const disabled = isCatalogLoading || isViewer;

  const onKeyDownWrapper = (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
    onArrowKeyPress(e, activeBox);

    if (selectedItemRef.current) {
      const target = selectedItemRef.current;
      const parent = target.parentNode as HTMLElement;

      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        if (e.key === 'ArrowDown') {
          parent.scrollTop -= parentRect.top - targetRect.top;
        }

        if (e.key === 'ArrowUp') {
          parent.scrollTop += targetRect.bottom - parentRect.bottom;
        }
      }
    }
  };

  const filteredGroups =
    selectedIDs.subtype && selectedIDs.subtype !== NEW_ITEM_ID.subtype
      ? groupItems.filter((gi) => gi.subtype?.id === selectedIDs.subtype)
      : groupItems;

  const isPipeType = type === 'Pipe';

  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="flex-start"
      sx={{ position: 'relative', height: '100%', overflowX: 'auto', width: '100%' }}
    >
      <ItemsBox
        isActive={activeBox === 'type'}
        onClick={() => setActiveBox('type')}
        header={<BoxHeader>{t('Select Type')}</BoxHeader>}
      >
        {catalogTypes.map((t) => (
          <Item
            key={t}
            ref={t === type ? (selectedItemRef as RefObject<HTMLButtonElement>) : null}
            disabled={isCatalogLoading}
            onClick={() => {
              onChangeManageType(t);
              setActiveBox('type');
            }}
            onKeyDown={onKeyDownWrapper}
            tabIndex={0}
            sx={{
              p: 1,
              ...(type === t && { bgcolor: 'primary.light', '&:hover': { bgcolor: 'primary.light' } }),
              ...(t === CATALOG_TYPE.DISTRIBUTION_CURVE && { display: 'none' }),
            }}
          >
            <Typography noWrap>{separateByUpperCase(t)}</Typography>
          </Item>
        ))}
      </ItemsBox>

      <ItemsBox
        onClick={() => setActiveBox('subtype')}
        isActive={activeBox === 'subtype'}
        sx={{ ...(isPipeType && { width: '520px', minWidth: '520px' }) }}
        header={
          <Box>
            {isPipeType ? (
              <Stack direction="row">
                <BoxHeader noWrap sx={{ width: '30%', borderRight: '1px solid lightgrey' }}>
                  {t('Sub Types')}
                </BoxHeader>
                <BoxHeader noWrap sx={{ width: '70%' }}>
                  {t('Included in')}
                </BoxHeader>
              </Stack>
            ) : (
              <BoxHeader>{t('Sub Types')}</BoxHeader>
            )}

            {!isViewer && (
              <AddItemButton
                isOpenInput={selectedIDs.subtype === NEW_ITEM_ID.subtype}
                onOpen={() => onChangeSelectedIDs(NEW_ITEM_ID.subtype, 'subtype')}
                onClose={() => onChangeSelectedIDs('', 'subtype')}
                onConfirm={onCreateSubtype}
                disabled={disabled}
                title={'+ ' + t('Add Subtype')}
                isLoading={loadingIDs.includes(NEW_ITEM_ID.subtype)}
                errorText={'Subtype name must be min 3 characters and max 50'}
              />
            )}
          </Box>
        }
      >
        {subtypeItems.map((s) => (
          <Box
            ref={s.id === selectedIDs.subtype ? (selectedItemRef as RefObject<HTMLDivElement>) : null}
            key={s.id}
          >
            <SubTypeItem
              subTypeInfo={s}
              onDelete={() => onDeleteSubtype(s.id)}
              onChangeSubtype={onChangeSubtype}
              isSelected={s.id === selectedIDs.subtype}
              onSelect={() => {
                onChangeSelectedIDs(s.id, 'subtype');
                setActiveBox('subtype');
              }}
              isPipeType={isPipeType}
              isLoading={loadingIDs.includes(s.id)}
              disabled={disabled}
              onKeyDown={onKeyDownWrapper}
            />
          </Box>
        ))}
      </ItemsBox>

      <ItemsBox
        isActive={activeBox === 'group'}
        onClick={() => setActiveBox('group')}
        sx={{ width: '520px', minWidth: '360px' }}
        header={
          <Box>
            <Stack direction="row">
              <BoxHeader noWrap sx={{ width: '30%', borderRight: '1px solid lightgrey' }}>
                {t('WCADI Group ID')}
              </BoxHeader>
              <BoxHeader noWrap sx={{ width: '35%', borderRight: '1px solid lightgrey' }}>
                {t('groups')}
              </BoxHeader>
              <BoxHeader noWrap sx={{ width: '35%' }}>
                {t('Select Sub Types')}
              </BoxHeader>
            </Stack>

            {!isViewer && (
              <AddItemButton
                isOpenInput={selectedIDs.group === NEW_ITEM_ID.group}
                onOpen={() => onChangeSelectedIDs(NEW_ITEM_ID.group, 'group')}
                onClose={() => onChangeSelectedIDs('', 'group')}
                onConfirm={onCreateGroup}
                disabled={disabled}
                title={'+ ' + t('Add Group')}
                errorText={'Group name must be min 3 characters and max 50'}
                isLoading={loadingIDs.includes(NEW_ITEM_ID.group)}
              />
            )}
          </Box>
        }
      >
        {filteredGroups.map((g) => (
          <Box ref={g.id === selectedIDs.group ? (selectedItemRef as RefObject<HTMLDivElement>) : null} key={g.id}>
            <GroupItem
              groupInfo={g}
              onDelete={() => onDeleteGroup(g.id)}
              onChangeGroup={onChangeGroup}
              subTypes={subtypeItems}
              isSelected={g.id === selectedIDs.group}
              onSelect={(id: string) => {
                onChangeSelectedIDs(id, 'group');
                setActiveBox('group');
              }}
              isLoading={loadingIDs.includes(g.id)}
              disabled={disabled || isGroupsDisabled}
              onKeyDown={onKeyDownWrapper}
            />
          </Box>
        ))}
      </ItemsBox>

      <Stack
        sx={{
          borderRight: '1px solid grey',
          borderColor: 'info.dark',
          flexGrow: 1,
          alignSelf: 'normal',
        }}
      />

      <ItemsBox
        isActive={activeBox === 'brand'}
        onClick={() => setActiveBox('brand')}
        header={
          <Box>
            <BoxHeader>{t('Brands')}</BoxHeader>

            {!isViewer && (
              <AddItemButton
                isOpenInput={selectedIDs.brand === NEW_ITEM_ID.brand}
                onOpen={() => onChangeSelectedIDs(NEW_ITEM_ID.brand, 'brand')}
                onClose={() => onChangeSelectedIDs('', 'brand')}
                onConfirm={onCreateBrand}
                disabled={disabled}
                title={'+ ' + t('Add Brand')}
                isLoading={loadingIDs.includes(NEW_ITEM_ID.brand)}
                errorText={'Brand name must be min 3 characters and max 50'}
              />
            )}
          </Box>
        }
      >
        {brandItems?.map((b) => (
          <Box ref={b.id === selectedIDs.brand ? (selectedItemRef as RefObject<HTMLDivElement>) : null} key={b.id}>
            <BrandItem
              brandInfo={b}
              onDelete={() => onDeleteBrand(b.id)}
              onSaveChangeBrandName={onChangeBrand}
              isSelected={b.id === selectedIDs.brand}
              onSelect={() => {
                onChangeSelectedIDs(b.id, 'brand');
                setActiveBox('brand');
              }}
              isLoading={loadingIDs.includes(b.id)}
              disabled={disabled}
              onKeyDown={onKeyDownWrapper}
            />
          </Box>
        ))}
      </ItemsBox>

      <Box
        onClick={() => onChangeSelectedIDs('')}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1,
          ml: '0 !important',
        }}
      />
    </Stack>
  );
};
