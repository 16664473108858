import { FC } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableProductInfo } from 'shared/models';
import { ItemsBox, SquareCheckBox } from 'shared/ui';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';

interface Props {
  isLoading: boolean;
  selectedProductIDs: string[];
  selectedViewProductItemsAmount: number;
  viewProducts: TableProductInfo[];
  onToggleAll: () => void;
  onToggleProduct: (id: string) => void;
  isActiveTable: boolean;
}

export const CatalogAvailabilityProducts: FC<Props> = ({
  isLoading,
  selectedProductIDs,
  selectedViewProductItemsAmount,
  viewProducts,
  onToggleAll,
  onToggleProduct,
  isActiveTable,
}) => {
  const { t } = useTranslation();

  const AvailabilityProductItem = (props: { params: GridRenderCellParams }) => {
    const id = props.params.id as string;
    const description = props.params.value;

    const handleToggle = () => onToggleProduct(id);

    return (
      <Stack
        direction="row"
        sx={{
          width: '100%',
          borderBottom: '1px solid grey',
          borderColor: 'info.light',
          height: '33px',
        }}
      >
        <SquareCheckBox disabled={isLoading} checked={selectedProductIDs.includes(id)} onToggle={handleToggle} />

        <Typography
          noWrap
          sx={{
            padding: '5px 15px',
            borderLeft: '1px solid grey',
            borderColor: 'info.light',
            width: '100%',
            color: isLoading ? 'info.dark' : 'black',
          }}
        >
          {description}
        </Typography>
      </Stack>
    );
  };

  const productColumn: GridColDef[] = [
    {
      field: 'description',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => <AvailabilityProductItem params={params} />,
      width: 2500,
    },
  ];

  return (
    <ItemsBox
      header={
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <SquareCheckBox
              checked={selectedViewProductItemsAmount === viewProducts.length && !!viewProducts.length}
              onToggle={onToggleAll}
              disabled={isLoading}
            />
            <Typography
              noWrap
              sx={{
                padding: '4px 8px',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderLeft: '1px solid grey',
                borderColor: 'info.light',
              }}
            >
              {t('Select Products')}
            </Typography>
          </Stack>

          <Typography sx={{ width: '100%', textAlign: 'right', pr: 1.5, color: 'info.dark', fontWeight: 700 }}>
            {`${selectedViewProductItemsAmount}/${viewProducts.length}`}
          </Typography>
        </Stack>
      }
      isActive={isActiveTable}
      sx={{ width: '100%', maxHeight: 'calc(100svh - 310px)', overflowX: 'hidden' }}
    >
      {viewProducts.length ? (
        <DataGridPremium
          rowHeight={33}
          columnHeaderHeight={0}
          hideFooter
          columns={productColumn}
          rows={viewProducts}
          disableRowSelectionOnClick
          sx={{
            minHeight: 0,
            maxHeight: '100%',
            overflowX: 'hidden',
            p: 0,
            border: 'none',
            borderRadius: 0,
            width: '100%',
            '*': { outline: 'none !important' },

            '& .MuiDataGrid-cell': {
              padding: '0 !important',
              border: 'none',
              width: '100%',
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'hidden',

              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'info.dark',
                borderRadius: '30px',
              },
              '&::-webkit-scrollbar': {
                width: '4px',
                backgroundColor: 'info.light',
              },
            },
            '& .MuiDataGrid-columnHeaders': {
              display: 'none',
            },
            '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-row.Mui-hovered.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'rgba(29, 135, 66, 0.08) !important',
            },
            '& .MuiDataGrid-cell.Mui-hovered.Mui-selected, & .MuiDataGrid-cell.Mui-selected:hover': {
              backgroundColor: 'rgba(29, 135, 66, 0.08) !important',
            },
          }}
        />
      ) : (
        <Stack alignItems={'center'} sx={{ height: '34px' }}>
          {isLoading ? (
            <CircularProgress size={15} color="primary" sx={{ m: '9px' }} />
          ) : (
            <Typography sx={{ p: '5px', textAlign: 'center' }}>Empty Group</Typography>
          )}
        </Stack>
      )}
    </ItemsBox>
  );
};
