import { IconButton, InputBase, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, startTransition, useEffect, useState } from 'react';
import { useAppDispatch, useCatalogSearchParams } from 'shared/hooks';
import { CellType } from 'shared/models';
import { setTableSearch, setTableSearchCurrentIdx } from 'shared/slices';
import { ArrowDown, CloseIcon } from 'shared/ui';
import { SearchIcon } from 'shared/ui/icons/SearchIcon';

const greyColor = 'rgba(77, 78, 76, 1)';
const lightGreyColor = 'rgba(140, 141, 146, 1)';

interface Props {
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
}

export const TableSearch: FC<Props> = ({ tableSearch }) => {
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();

  const { search, currentIdx, cells } = tableSearch ?? { search: '', currentIdx: 0, cells: [] };

  const [isFocused, setIsFocused] = useState(false);

  const disableArrow = cells.length === 1;
  const color = disableArrow ? lightGreyColor : greyColor;

  useEffect(() => {
    window.addEventListener('keydown', handleEnterKeydown);
    return () => window.removeEventListener('keydown', handleEnterKeydown);
  }, []);

  const [inputValue, setInputValue] = useState(search);

  const onChangeTableSearch = (value: string) => catalogId && dispatch(setTableSearch({ catalogId, value }));
  const onMoveToNewCurrentIdx = (moveTo: 'next' | 'prev') =>
    catalogId && dispatch(setTableSearchCurrentIdx({ catalogId, moveTo }));

  const handleEnterKeydown = (e: KeyboardEvent) => {
    if (e.code == 'Enter') {
      e.preventDefault();
      onMoveToNewCurrentIdx('next');
    }
  };

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;
    setInputValue(value);

    startTransition(() => {
      onChangeTableSearch(value);
    });
  };

  const handleClearInputValue = () => {
    setInputValue('');

    startTransition(() => {
      onChangeTableSearch('');
    });
  };

  return (
    <Stack direction="row" sx={{ borderBottom: '1px solid grey', borderColor: greyColor, ml: '60px' }}>
      <SearchIcon
        width="25"
        height="25"
        viewBox="0 0 25 25"
        stroke={isFocused || search ? greyColor : lightGreyColor}
      />

      <InputBase
        value={inputValue}
        onChange={handleChangeInputValue}
        placeholder="Search"
        sx={{
          p: 0,
          minWidth: '160px',
          width: 'auto',
          overflow: 'hidden',
        }}
        inputProps={{
          style: { width: `${(inputValue || 'Search').length + 1}ch` },
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />

      {search && (
        <>
          {!!cells.length && (
            <>
              <Typography sx={{ color }}>{`(${currentIdx + 1}/${cells.length})`}</Typography>

              <IconButton onClick={() => onMoveToNewCurrentIdx('next')} disabled={disableArrow} sx={{ p: 0 }}>
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
              <IconButton
                onClick={() => onMoveToNewCurrentIdx('prev')}
                disabled={disableArrow}
                sx={{ p: 0, rotate: '180deg' }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
            </>
          )}

          <IconButton onClick={handleClearInputValue} sx={{ p: 0 }}>
            <CloseIcon width="32" height="32" viewBox="0 0 32 32" stroke={greyColor} />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
