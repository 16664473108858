import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogModal, ErrorTooltip, Icon, SaveControl } from 'shared/ui';
import { SubType } from 'shared/models';

interface Props {
  subTypeInfo: SubType;
  onDelete: () => void;
  onChangeSubtype: (
    id: string,
    updateData: {
      subtypeName: string;
      isLateral: boolean;
      isSubMain: boolean;
      isMainPipe: boolean;
    }
  ) => void;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isLoading?: boolean;
  disabled: boolean;
  isPipeType: boolean;
}

export const SubTypeItem: FC<Props> = ({
  subTypeInfo: { id, name, isLateral, isMainPipe, isSubMain },
  onDelete,
  onChangeSubtype,
  isSelected,
  onSelect,
  onKeyDown,
  isLoading,
  disabled,
  isPipeType,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isConfirm, setIsConfirm] = useState(false);
  const onToggleConfirm = () => setIsConfirm((c) => !c);

  const [nameValue, setNameValue] = useState<string>(name);
  const [includedInOptions, setIncludedInOptions] = useState({
    isLateral: isLateral ?? false,
    isMainPipe: isMainPipe ?? false,
    isSubMain: isSubMain ?? false,
  });
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const [isEdit, setIsEdit] = useState(false);

  const checkboxOptions = [
    { name: 'isLateral', value: includedInOptions.isLateral, label: 'Lateral' },
    { name: 'isSubMain', value: includedInOptions.isSubMain, label: 'Submain' },
    { name: 'isMainPipe', value: includedInOptions.isMainPipe, label: 'Main Pipe' },
  ];

  const onOpenEdit = () => {
    setIsEdit(true);
    onSelect(id);
    setNameValue(name);
  };
  const onCancelEdit = () => {
    setIsEdit(false);
    onSelect('');
    setNameValue(name);
  };

  const handleDelete = () => {
    onToggleConfirm();
    onDelete();
  };
  const handleSelect = (e: any) => {
    e.stopPropagation();
    if (disabled || isEdit) return;
    onSelect(id);
  };

  const handleSaveNameEditing = () => {
    if (nameValue === name) return;
    const updateData = {
      subtypeName: nameValue,
      ...includedInOptions,
    };
    onChangeSubtype(id, updateData);
    setIsEdit(false);
  };

  const toggleIncludedInOptions = (optionName: string, optionValue: boolean) => {
    const updateData = {
      subtypeName: nameValue,
      ...includedInOptions,
      [optionName]: !optionValue,
    };
    onChangeSubtype(id, updateData);
    setIncludedInOptions((prev) => ({ ...prev, [optionName]: !optionValue }));
  };

  useEffect(() => {
    if (isEdit) {
      inputRef.current && inputRef.current?.focus();
    }
  }, [isEdit]);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '32px',
          borderBottom: '1px solid lightgrey',
          color: disabled ? 'info.dark' : 'black',
          cursor: 'pointer',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          onClick={handleSelect}
          onKeyDown={onKeyDown}
          tabIndex={0}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            p: '5px',
            pl: '7px',
            width: isPipeType ? '30%' : '100%',
            height: '32px',
            borderBottom: '1px solid lightgrey',
            borderRight: '1px solid lightgrey',
            bgcolor: isSelected ? 'primary.light' : '#fff',
            color: disabled ? 'info.dark' : 'black',
            cursor: 'pointer',
            outline: 'none',
            '&:focus': { outline: 'none' },
          }}
        >
          {isEdit ? (
            <>
              <ErrorTooltip
                open={nameValue.length < 3 || nameValue.length > 50}
                title={'Group name must be between 3 and 50 characters.'}
                arrow
              >
                <InputBase
                  value={nameValue}
                  onChange={onChangeValue}
                  inputRef={inputRef}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.code === 'Enter') {
                      handleSaveNameEditing();
                    }
                  }}
                  sx={{ mt: '1px', input: { color: 'primary.main' } }}
                />
              </ErrorTooltip>
              <Stack direction="row">
                <IconButton onClick={onCancelEdit} sx={{ p: 0 }}>
                  <Icon path="circle-btn-close" />
                </IconButton>
                <IconButton onClick={handleSaveNameEditing} sx={{ p: 0 }}>
                  <Icon path="circle-btn-done" />
                </IconButton>
              </Stack>
            </>
          ) : (
            <>
              <Typography noWrap sx={{ width: '100%' }}>
                {name}
              </Typography>

              {!isSelected && isLoading && <CircularProgress size={15} color="primary" />}

              {isSelected && (
                <Stack direction="row" sx={{ mr: -0.5 }}>
                  <IconButton onClick={onOpenEdit} sx={{ p: '3px' }}>
                    <Icon path="group-edit" />
                  </IconButton>
                  <IconButton onClick={onToggleConfirm} sx={{ p: '3px' }}>
                    <Icon path="group-delete" />
                  </IconButton>
                </Stack>
              )}
            </>
          )}
        </Stack>

        {isPipeType && (
          <Stack
            direction="row"
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '70%',
              height: '32px',
              borderBottom: '1px solid lightgrey',
              color: disabled ? 'info.dark' : 'black',
              cursor: 'pointer',
              outline: 'none',
              '&:focus': { outline: 'none' },
            }}
          >
            {checkboxOptions.map(({ name, value, label }, index) => (
              <FormControlLabel
                key={name}
                checked={value}
                onChange={() => toggleIncludedInOptions(name, value ?? false)}
                label={
                  <Typography noWrap sx={{ color: 'darksome.main' }}>
                    {label}
                  </Typography>
                }
                disabled={isLoading}
                control={
                  <Checkbox
                    icon={<Icon path="checkbox-green-icon" />}
                    checkedIcon={<Icon path="checkbox-green-icon-checked" />}
                    sx={{ mr: 1, width: 20, height: 20 }}
                  />
                }
                sx={{
                  justifyContent: 'center',
                  flexGrow: 1,
                  m: 0,
                  height: '100%',
                  ...(index !== checkboxOptions.length - 1 && { borderRight: '1px solid lightgrey' }),
                }}
              />
            ))}
          </Stack>
        )}
      </Stack>

      <DialogModal open={isConfirm} onClose={onToggleConfirm} maxWidth="xs">
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
          By deleting the sub type it will be removed from all linked groups
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>Are you sure ?</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl onSave={handleDelete} onCancel={onToggleConfirm} btnTexts={[t('yes'), t('no')]} />
        </Stack>
      </DialogModal>
    </>
  );
};
