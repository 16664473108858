import { FC } from 'react';
import {
  Box,
  Button as MuiButton,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Popover,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { DisabledFade, HoverToolTip, Icon } from 'shared/ui';
import { SORTING } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { TableProductInfo } from 'shared/models';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { UniqFilterItem } from './components';
import { useCatalogItemsFilter } from './hooks';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: 0,
  height: '30px',
  textTransform: 'none',
  paddingInline: '16px',
  paddingBlock: '8px',
  color: theme.palette.darksome?.main,
  textAlign: 'left',
  justifyContent: 'flex-start',
}));

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  field: string;
  isNumberType: boolean;
  isEditing: boolean;
  sorting: { field: string; value: SORTING };
  filters: { field: string; values: string[] }[];
  items: TableProductInfo[];
  hidedItems: TableProductInfo[];
  allComponents: TableProductInfo[];
}

export const CatalogItemsFilter: FC<Props> = ({
  open,
  anchorEl,
  onClose,
  field,
  isNumberType,
  isEditing,
  sorting,
  filters,
  items,
  hidedItems,
  allComponents,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const sortingTextASC = isNumberType ? t('Smallest To Largest') : t('A to Z');
  const sortingTextDESC = isNumberType ? t('Largest To Smallest') : t('Z to A');

  const {
    checkedFilterValues,
    onChangeCheckedFilterValues,
    handleChangeSort,
    sortingValue,
    isPendingTransition,
    isSelectedASC,
    isSelectedDESC,
    searchValue,
    onChangeSearchValue,
    allUniqItemsValues,
    handleToggleSelectAll,
    sortedSearchedItemsValues,
    isFilteredByCurrentField,
    handleResetFilter,
    handleOK,
  } = useCatalogItemsFilter(field, sorting, filters, open, onClose, items, hidedItems, allComponents);

  const filterColumn: GridColDef[] = [
    {
      field: 'name',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <UniqFilterItem
          params={params}
          openedFilterValues={checkedFilterValues}
          onChangeOpenedFilterValues={onChangeCheckedFilterValues}
          isEditing={isEditing}
        />
      ),
      width: 194,
    },
  ];

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ '& .MuiPaper-root': { borderRadius: '4px', ml: -4 } }}
    >
      <Stack
        sx={{
          width: '200px',
          bgcolor: palette.info.main,
          zIndex: 1000,
          py: '8px',
        }}
      >
        <Box sx={{ backgroundColor: 'info.main', borderRadius: '10px' }}>
          <Typography sx={{ px: '16px' }}>{t('Sort')}</Typography>

          <Button
            fullWidth
            onClick={() => handleChangeSort(SORTING.ASC)}
            sx={{
              bgcolor: sortingValue === SORTING.ASC ? 'primary.light' : 'info.main',
              '&:hover': { bgcolor: sortingValue === SORTING.ASC ? 'primary.light' : 'info.main' },
            }}
            disabled={isPendingTransition}
          >
            <Icon path={`sort-az-${isSelectedASC ? 'black' : 'grey'}`} sx={{ mr: 1 }} />
            {sortingTextASC}
          </Button>
          <Button
            fullWidth
            onClick={() => handleChangeSort(SORTING.DESC)}
            sx={{
              bgcolor: sortingValue === SORTING.DESC ? 'primary.light' : 'info.main',
              '&:hover': { bgcolor: sortingValue === SORTING.DESC ? 'primary.light' : 'info.main' },
            }}
            disabled={isPendingTransition}
          >
            <Icon path={`sort-za-${isSelectedDESC ? 'black' : 'grey'}`} sx={{ mr: 1 }} />
            {sortingTextDESC}
          </Button>
        </Box>

        <HoverToolTip
          title={t('Can’t change while editing')}
          disableHoverListener={!isEditing}
          disableFocusListener={!isEditing}
          disableTouchListener={!isEditing}
          placement="bottom"
        >
          <Box sx={{ position: 'relative' }}>
            <InputBase
              value={searchValue}
              onChange={onChangeSearchValue}
              placeholder={t('search') + '...'}
              sx={{ px: '16px' }}
              startAdornment={<Icon path={'grey-search'} sx={{ mr: 0.5 }} />}
            />
            <Divider />

            {!searchValue && (
              <Stack direction="row" sx={{ width: '100%' }}>
                <FormControlLabel
                  checked={allUniqItemsValues.length === checkedFilterValues.length}
                  onChange={handleToggleSelectAll}
                  label={<Box sx={{ color: 'darksome.main' }}>{t('Select All')}</Box>}
                  disabled={isEditing}
                  control={
                    <Checkbox
                      icon={<Icon path="checkbox-icon" />}
                      checkedIcon={<Icon path="checkbox-icon-checked" />}
                    />
                  }
                  sx={{ m: 0, pl: '10px', bgcolor: 'info.main', width: '100%', border: 'none' }}
                />
              </Stack>
            )}

            {sortedSearchedItemsValues.length ? (
              <>
                <DataGridPremium
                  rowHeight={36}
                  columnHeaderHeight={0}
                  hideFooter
                  columns={filterColumn}
                  rows={sortedSearchedItemsValues}
                  sx={{
                    minHeight: 0,
                    maxHeight: '200px',
                    overflowX: 'hidden',
                    p: 0,
                    border: 'none',
                    borderRadius: 0,

                    '& .MuiDataGrid-cell': {
                      padding: '0 !important',
                      border: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'info.dark',
                        borderRadius: '30px',
                      },
                      '&::-webkit-scrollbar': {
                        width: '4px',
                        backgroundColor: 'info.light',
                      },
                    },
                  }}
                />

                <DisabledFade open={isEditing} />
              </>
            ) : (
              <Typography sx={{ p: '10px 18px', fontWeight: 600, textAlign: 'center' }}>No data</Typography>
            )}
          </Box>
        </HoverToolTip>

        <Divider />
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: '10px' }}>
          {isFilteredByCurrentField && (
            <HoverToolTip title={t('Can’t change while editing')} disableHoverListener={!isEditing}>
              <IconButton onClick={handleResetFilter} disabled={isEditing}>
                <Icon path={'reset-filters-grey'} />
              </IconButton>
            </HoverToolTip>
          )}

          <Stack direction="row" alignItems={'center'} sx={{ ml: 'auto', height: '36px' }}>
            <Button onClick={onClose} sx={{ minWidth: 'auto', borderRadius: 1 }} disabled={isPendingTransition}>
              {t('cancel')}
            </Button>
            <Button
              onClick={handleOK}
              sx={{ px: '5px', borderRadius: 1, minWidth: 'auto', color: 'primary.main' }}
              disabled={isPendingTransition}
            >
              {t('Ok')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  );
};
