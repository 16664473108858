import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { CircularProgress, IconButton, InputBase, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogModal, ErrorTooltip, Icon, SaveControl } from 'shared/ui';
import { Brand } from 'shared/models';

interface Props {
  brandInfo: Brand;
  onDelete: () => void;
  onSaveChangeBrandName: (id: string, name: string) => void;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isLoading?: boolean;
  disabled: boolean;
}

export const BrandItem: FC<Props> = ({
  brandInfo: { id, name },
  onDelete,
  onSaveChangeBrandName,
  isSelected,
  onSelect,
  onKeyDown,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isConfirm, setIsConfirm] = useState(false);
  const onToggleConfirm = () => setIsConfirm((c) => !c);

  const [value, setValue] = useState<string>(name);
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const [isEdit, setIsEdit] = useState(false);
  const onOpenEdit = () => {
    setIsEdit(true);
    onSelect(id);
    setValue(name);
  };
  const onCancelEdit = () => {
    setIsEdit(false);
    onSelect('');
    setValue(name);
  };

  const handleDelete = () => {
    onToggleConfirm();
    onDelete();
  };
  const handleSelect = (e: any) => {
    e.stopPropagation();
    if (disabled || isEdit) return;
    onSelect(id);
  };

  const handleSaveEditing = () => {
    if (value === name) return;
    onSaveChangeBrandName(id, value);
    setIsEdit(false);
    setValue(value);
  };

  useEffect(() => {
    if (isEdit) {
      inputRef.current && inputRef.current?.focus();
    }
  }, [isEdit]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        onClick={handleSelect}
        onKeyDown={onKeyDown}
        tabIndex={0}
        sx={{
          p: '5px',
          pl: '7px',
          bgcolor: isSelected ? 'primary.light' : '#fff',
          borderBottom: '1px solid lightgrey',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: '32px',
          color: disabled ? 'info.dark' : 'black',
          outline: 'none',
          '&:focus': { outline: 'none' },
        }}
      >
        {isEdit ? (
          <>
            <ErrorTooltip
              open={value.length < 3 || value.length > 50}
              title={'Group name must be between 3 and 50 characters.'}
              arrow
            >
              <InputBase
                value={value}
                onChange={onChangeValue}
                inputRef={inputRef}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.code === 'Enter') {
                    handleSaveEditing();
                  }
                }}
                sx={{ mt: '1px', input: { color: 'primary.main' } }}
              />
            </ErrorTooltip>
            <Stack direction="row">
              <IconButton onClick={onCancelEdit} sx={{ p: 0 }}>
                <Icon path="circle-btn-close" />
              </IconButton>
              <IconButton onClick={handleSaveEditing} sx={{ p: 0 }}>
                <Icon path="circle-btn-done" />
              </IconButton>
            </Stack>
          </>
        ) : (
          <>
            <Typography noWrap sx={{ width: '100%' }}>
              {name}
            </Typography>

            {!isSelected && isLoading && <CircularProgress size={15} color="primary" />}

            {isSelected && (
              <Stack direction="row" sx={{ mr: -0.5 }}>
                <IconButton onClick={onOpenEdit} sx={{ p: '3px' }}>
                  <Icon path="group-edit" />
                </IconButton>
                <IconButton onClick={onToggleConfirm} sx={{ p: '3px' }}>
                  <Icon path="group-delete" />
                </IconButton>
              </Stack>
            )}
          </>
        )}
      </Stack>

      <DialogModal open={isConfirm} onClose={onToggleConfirm} maxWidth="xs">
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
          By deleting the brand it will be removed from all linked components
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>Are you sure ?</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl onSave={handleDelete} onCancel={onToggleConfirm} btnTexts={[t('yes'), t('no')]} />
        </Stack>
      </DialogModal>
    </>
  );
};
